import React, { ReactElement } from "react";
import CircleSvg from "./circleSvg";
import colors from "@styles/variables/quotoVariables.module.scss";

export default function SimpleProcess(): ReactElement {
    return (
        <section
            style={{
                backgroundColor: colors.blue,
            }}
            className="my-16 lg:my-24 flex flex-col-reverse md:flex-row md:items-center bg-[salmon] p-8 rounded-[10px]"
        >
            <div className="w-full md:w-3/4">
                <h2
                    style={{ color: "#fff" }}
                    className="font-bold text-2xl lg:text-4xl"
                >
                    Why Quoto.com
                </h2>
                <p
                    style={{ color: "#fff" }}
                    className="font-light mt-4 lg:mt-7 lg:text-lg "
                >
                    At Quoto.com, we understand the challenges that businesses
                    face in today's fast-paced market. That's why we offer a
                    platform that streamlines the process of getting quotes for
                    essential products and services. With just a few clicks, you
                    can compare quotes from a variety of providers and choose
                    the best one for your business. Not only does this save you
                    time, but it also helps you get the best deal.
                </p>
                <p
                    style={{ color: "#fff" }}
                    className="font-light mt-4 lg:mt-7 lg:text-lg "
                >
                    Our team of experts works tirelessly to provide accurate and
                    up-to-date information so you can make informed decisions.
                    Plus, our service is completely free, so there's no risk or
                    obligation involved. Choose Quoto.com for all your business
                    needs, and enjoy the convenience of having everything in one
                    place.
                </p>
            </div>
            <div className="md:mt-[-40px]  mb-10 md:mb-0">
                <CircleSvg />
            </div>
        </section>
    );
}
