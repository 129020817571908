import React, { ReactElement } from "react";

export default function CircleSvg(): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="252"
            height="268"
            fill="none"
            viewBox="0 0 252 268"
        >
            <g clipPath="url(#clip0_1_2363)">
                <path
                    fill="#477EA8"
                    d="M61.153 262.571c0-3.008 30.87-5.431 68.947-5.431s68.948 2.432 68.948 5.431S168.178 268 130.1 268c-38.08 0-68.947-2.43-68.947-5.429z"
                ></path>
                <path
                    fill="#00B9C3"
                    d="M150.997 174.271a.437.437 0 01-.385-.225.43.43 0 01-.041-.336.45.45 0 01.079-.154c5.049-6.434 16.874-21.498 39.027-29.021a.446.446 0 01.487.118.436.436 0 01.112.328.45.45 0 01-.315.391c-21.905 7.432-33.615 22.36-38.616 28.733a.45.45 0 01-.348.166z"
                ></path>
                <path
                    fill="#00B9C3"
                    d="M184.716 154.422a.447.447 0 01-.317-.137c-2.637-2.728-5.554-3.678-6.366-3.797a.443.443 0 01.127-.876c.957.14 4.055 1.138 6.878 4.059a.456.456 0 01.115.227.445.445 0 01-.434.524h-.003zM180.44 149.283a.444.444 0 01-.41-.609c.907-2.235 1.945-5.418 2.06-7.126a.445.445 0 01.473-.411.441.441 0 01.411.473c-.146 2.074-1.404 5.624-2.122 7.397a.44.44 0 01-.412.276zM170.717 155.029a.446.446 0 01-.368-.197.436.436 0 01-.072-.203.428.428 0 01.031-.212c.708-1.71 1.638-5.706 1.385-6.941a.433.433 0 01.064-.332.442.442 0 01.802.155c.313 1.536-.756 5.813-1.434 7.456a.445.445 0 01-.408.274zM175.395 159.838a.443.443 0 01-.301-.116c-2.861-2.655-5.673-2.345-7.349-2.166-.177.017-.336.035-.481.047a.44.44 0 01-.325-.101.442.442 0 01-.049-.625.444.444 0 01.3-.155l.461-.046c1.707-.177 4.886-.531 8.045 2.396a.446.446 0 01.11.486.442.442 0 01-.411.28zM161.75 162.3a.419.419 0 01-.152-.029.426.426 0 01-.249-.229.445.445 0 01-.013-.339l.177-.481c.734-1.946 1.739-4.617.37-6.243a.437.437 0 01-.054-.489.453.453 0 01.259-.218.454.454 0 01.337.029.444.444 0 01.135.108c1.701 2.023.591 4.973-.221 7.126l-.177.475a.443.443 0 01-.412.29zM166.892 165.629a.443.443 0 01-.232-.066c-3.107-1.918-5.264-1.416-7.167-.978a.438.438 0 01-.46-.175.438.438 0 01-.077-.33.446.446 0 01.337-.359c1.967-.453 4.421-1.021 7.831 1.088a.447.447 0 01.194.498.447.447 0 01-.426.322zM156.34 167.842a.34.34 0 01-.078 0 .443.443 0 01-.283-.183.436.436 0 01-.071-.33c.485-2.716.186-5.284-.746-6.39a.442.442 0 11.676-.571c1.345 1.592 1.367 4.724.941 7.117a.445.445 0 01-.439.357zM160.232 173.316a.444.444 0 01-.26-.085c-2.677-1.947-6.382-.793-7.851-.207a.45.45 0 01-.339-.005.435.435 0 01-.236-.243.45.45 0 01.005-.339.435.435 0 01.243-.236c1.604-.639 5.658-1.888 8.698.315a.44.44 0 010 .715.449.449 0 01-.26.085z"
                ></path>
                <path
                    fill="#fff"
                    d="M193.374 145.463a2.219 2.219 0 00.053-2.458 2.21 2.21 0 10-3.132 3.001 2.215 2.215 0 003.079-.543zM184.071 142.263a1.695 1.695 0 10-2.778-1.944 1.695 1.695 0 002.778 1.944zM186.833 155.646a1.702 1.702 0 00.043-1.883 1.697 1.697 0 00-3.084.541c-.072.328-.046.669.075.982a1.69 1.69 0 002.966.36zM173.19 147.388a1.697 1.697 0 00-1.684-2.641 1.69 1.69 0 00-1.359 1.303 1.695 1.695 0 003.043 1.338zM168.915 166.131a1.696 1.696 0 00-.704-2.524 1.696 1.696 0 10-.389 3.221 1.693 1.693 0 001.093-.697zM156.434 160.829a1.693 1.693 0 00.042-1.883 1.693 1.693 0 00-3.083.544 1.69 1.69 0 001.947 2.035 1.694 1.694 0 001.094-.696zM177.001 160.773a1.9 1.9 0 00-2.871-2.461 1.909 1.909 0 00-.46 2.059 1.905 1.905 0 001.358 1.17 1.885 1.885 0 001.436-.253 1.91 1.91 0 00.537-.515zM163.623 155.154a1.9 1.9 0 00-2.871-2.46 1.9 1.9 0 102.871 2.46zM162.489 174.613a1.9 1.9 0 10-2.653.465c.205.144.436.247.68.302a1.916 1.916 0 001.437-.252c.211-.135.393-.31.536-.515z"
                ></path>
                <path
                    fill="#133854"
                    d="M160.147 220.91c-5.917-15.218 3.668-27.094 22.362-31.522 2.598 14.796-4.566 32.683-22.362 31.522z"
                ></path>
                <path
                    fill="#000"
                    d="M151.684 220.264c-14.285-12.458-10.828-29.858 6.013-44.498 10.661 13.821 12.925 36.091-6.013 44.498z"
                ></path>
                <path
                    fill="#DDE2F8"
                    d="M115.785 221.15c4.391-5.05 10.945-21.057-.406-30.624-5.138-4.33-6.743-5.172-9.429-4.845-1.749.214-3.102 1.269-4.351.312-2.281-1.749 4.287-10.062-3.383-17.183s-14.023-3.008-16.073-4.955c-2.05-1.946-.104-5.857-4.732-7.805-4.628-1.949-7.958 5.415-14.239 3.716-6.543-1.77-18.022-2.354-19.664 3.716-1.643 6.07 3.473 7.204 5.138 11.223 1.666 4.018-1.881 15.926 7.781 18.546 9.604 2.6 13.537 2.186 14.779 14.353 1.241 12.168 12.295 17.942 18.274 16.863 5.98-1.08 4.467 2.036 1.38 3.767-3.088 1.731 1.768 4.305 6.19 3.76 4.42-.545 13.221-4.507 18.735-10.844z"
                ></path>
                <path
                    fill="#76AFDA"
                    d="M117.614 224.438a.444.444 0 01-.225-.061.446.446 0 01-.162-.169c-16.002-29.309-43.082-48.256-63.088-56.219a.44.44 0 01-.247-.575.442.442 0 01.574-.248c20.15 8.018 47.422 27.099 63.535 56.611a.436.436 0 01.037.337.457.457 0 01-.424.324z"
                ></path>
                <path
                    fill="#76AFDA"
                    d="M71.123 176.483a.442.442 0 01-.442-.433c-.026-1.625.741-8.247 2.725-11.759a.439.439 0 01.603-.168.44.44 0 01.168.603c-1.872 3.314-2.636 9.796-2.612 11.308a.439.439 0 01-.124.314.44.44 0 01-.31.135h-.008zM58.009 178.467a.439.439 0 01-.419-.299.44.44 0 01.146-.492c1.4-1.103 6.543-3.971 8.975-4.566a.442.442 0 01.483.66c-.06.1-.159.172-.273.2-2.288.559-7.323 3.362-8.638 4.401a.441.441 0 01-.274.096zM88.133 188.734a.445.445 0 01-.44-.398c-.177-1.701-.688-6.879 3.112-13.472a.442.442 0 01.766.442c-3.657 6.346-3.162 11.311-3.007 12.941a.443.443 0 01-.396.485l-.035.002zM69.693 188.327a.446.446 0 01-.43-.331.445.445 0 01.214-.499c2.88-1.614 9.903-4.713 12.257-4.734a.46.46 0 01.318.128.448.448 0 01.007.63.423.423 0 01-.142.097.428.428 0 01-.17.035c-2.137.02-8.953 3.009-11.832 4.622a.455.455 0 01-.222.052zM90.872 203.977a.44.44 0 01-.427-.331.443.443 0 01.211-.497c.391-.22.808-.466 1.238-.722 2.3-1.349 5.159-3.026 8.022-3.276a.438.438 0 01.431.235.443.443 0 01.046.167.443.443 0 01-.401.479c-2.665.232-5.43 1.855-7.65 3.157-.444.26-.865.508-1.261.731a.437.437 0 01-.209.057zM105.964 207.001a.44.44 0 01-.43-.342c-.73-3.07.069-9.037 1.781-13.3a.44.44 0 01.571-.237.442.442 0 01.25.567c-1.656 4.119-2.435 9.845-1.742 12.767a.452.452 0 01-.054.333.435.435 0 01-.273.198.51.51 0 01-.103.014z"
                ></path>
                <path
                    fill="#E9EDF7"
                    d="M146.874 226.535c-.695-2.817 16.165-24.319 20.485-21.36 3.36 2.3 1 5.924.036 7.174-1.516 1.966-5.47 6.455-3.838 7.347 2.952 1.616 11.495-11.019 13.778-13.967 2.283-2.948 7.489-8.059 11.592-7.696 4.103.363 5.722.982 1.459 5.921-4.264 4.939-13.877 13.626-9.109 13.59 4.767-.035 10.661-8.494 15.822-12.116 5.16-3.622 16.209-8.745 16.237-4.019.028 4.228-16.573 12.623-13.999 14.612 2.059 1.592 5.68-2.4 12.876-6.525s14.029-7.943 16.871-3.893c2.283 3.254-2.881 4.921-6.733 7.194-3.851 2.272-9.095 5.641-6.757 6.634 2.338.992 20.098-11.943 24.956-7.512 3.213 2.934-1.452 4.489-5.785 7.386-4.332 2.897-5.641 4.247-4.774 4.9.866.653 7.337-2.301 10.852-4.013 3.516-1.713 9.143-2.982 10.858.24 1.716 3.223-4.205 5.392-6.576 5.688-2.372.295-4.508 1.592-4.421 2.3.086.708 7.991.29 7.565 4.392-.426 4.102-8.013 3.764-11.274 1.239-3.261-2.525-7.754-5.804-8.665-4.615-.91 1.189 2.858 4.107 5.836 6.402s3.634 3.893 3.022 4.686c-2.039 2.645-7.63 1.888-9.804.209-2.173-1.68-13.137-12.672-14.501-10.915-1.363 1.757 3.183 5.447 5.596 7.303 2.412 1.856 8.01 6.183 5.738 9.131-2.272 2.948-11.051-1.292-15.41-5.11-4.359-3.819-10.952-10.535-12-9.174-1.049 1.361 3.33 7.638 6.248 9.888 2.917 2.251 9.261 4.8 6.463 8.429-2.798 3.629-7.822.927-13.551-3.497-2.393-1.847-8.273-6.482-9.933-10.971-1.892-5.118-3.673-7.356-4.885-6.976-2.412.761-.145 5.044 2.163 7.73 2.308 2.686 9.988 10.605 5.238 13.448-4.75 2.844-9.302-5.099-11.448-8.113-2.147-3.014-8.795-14.282-11.27-12.854-2.476 1.428 2.606 6.445 4.016 8.166 1.409 1.722 3.193 6.219 1.124 6.475-2.069.257-22.858-12.128-24.098-17.158z"
                ></path>
                <path
                    fill="#00B9C3"
                    d="M13.691 199.424c-5.027 1.75-8.226-2.123-9.927-4.578 1.577-.419 8.065-1.729 9.928 4.578z"
                ></path>
                <path
                    fill="#00B9C3"
                    d="M51.519 227.528a.438.438 0 01-.354-.177c-6.68-8.586-20.262-20.431-38.37-27.89a.443.443 0 01.337-.819c18.259 7.531 31.98 19.491 38.728 28.173a.442.442 0 01-.12.643.438.438 0 01-.234.065l.013.005zM48.471 219.991c-2.843-10.371 4.087-14.156 9.258-17.262.63 3.24 3.71 14.359-9.258 17.262zM41.227 212.665c-4.281-7.497.354-11.829 3.752-15.271 1.132 2.393 5.751 10.421-3.752 15.271zM31.912 206.412c-5.084-5.403-2.085-9.954.066-13.516 1.43 1.769 6.9 7.551-.066 13.516zM22.446 201.115c-3.97-2.344-2.967-5.581-2.299-8.079 1.159.808 5.424 3.31 2.3 8.079zM43.876 223.21c-8.639-3.917-12.9 1.593-16.317 5.677 2.733 1 11.988 5.229 16.317-5.677z"
                ></path>
                <path
                    fill="#00B9C3"
                    d="M35.854 215.391c-6.835-4.5-11.207-.372-14.668 2.654 2.19 1.209 9.507 6.057 14.668-2.654zM25.01 207.615c-5.338-1.662-7.427 1.876-9.128 4.511 1.67.385 7.39 2.183 9.128-4.511zM17.34 202.892c-2.88-1.523-4.454.28-5.711 1.607.918.398 4 2.042 5.712-1.607z"
                ></path>
                <path
                    fill="#FCBA06"
                    d="M105.147 254.875c7.478-4.914 25.905-41.146 11.418-48.168-13.731-6.655-18.087 21.686-18.904 25.365-2.863-13.905-6.606-27.403-15.77-24.951-9.164 2.453 0 22.088 0 22.088-8.345-10.635-29.145-27.002-38.611-22.089-9.467 4.912 19.23 22.533 19.23 22.533-13.041-7.402-52.013-17.368-13.087 1.446-17.82-5.767-52.57-8.598-13.942 3.433-23.25-2.019-35.05 1.147-35.458 4.424-.409 3.277 6.41 7.901 36.457 8.671-14.84 2.454-22.282 5.928-18.46 7.255l87.127-.007z"
                ></path>
                <path
                    fill="#fff"
                    d="M55.356 237.393a1.21 1.21 0 00-2.062-.856 1.21 1.21 0 00.184 1.86 1.207 1.207 0 001.878-1.004zM96.793 244.502a1.86 1.86 0 00-3.172-1.315 1.858 1.858 0 00.281 2.861 1.853 1.853 0 002.347-.231c.349-.349.544-.822.544-1.315zM72.607 230.731a1.594 1.594 0 00-1.902-1.562 1.586 1.586 0 00-1.25 1.251 1.596 1.596 0 00.676 1.635 1.591 1.591 0 002.476-1.324z"
                ></path>
                <path
                    fill="#76AFDA"
                    d="M222.579 63.089c9.33 6.683 19.099 2.215 25.361-9.763-9.624-4.348-23.044-2.575-25.361 9.763z"
                ></path>
                <path
                    fill="#E9EDF7"
                    d="M217.608 62.907c16.8-2.3 22.765-17.025 17.331-36.268-14.745 5.11-27.373 20.665-17.331 36.267z"
                ></path>
                <path
                    fill="#fff"
                    d="M217.608 63.349a.434.434 0 01-.321-.129.453.453 0 01-.13-.312c0-.059.012-.116.034-.17 1.238-3.017 3.726-7.87 6.366-13.011 3.183-6.211 6.8-13.254 9.065-18.715a.44.44 0 01.812.003.442.442 0 01.005.335c-2.276 5.496-5.891 12.553-9.091 18.785-2.628 5.123-5.109 9.961-6.331 12.943a.443.443 0 01-.409.27z"
                ></path>
                <path
                    fill="#00B9C3"
                    d="M216.841 56.812h-41.115c-6.959 0-12.6 5.645-12.6 12.608v32.375c0 6.963 5.641 12.608 12.6 12.608h41.115c6.959 0 12.6-5.645 12.6-12.608V69.42c0-6.963-5.641-12.608-12.6-12.608z"
                ></path>
                <path
                    fill="#006F75"
                    d="M223.938 59.001a12.574 12.574 0 012.196 7.101v33.843c0 2.957-1.174 5.793-3.263 7.883a11.136 11.136 0 01-7.878 3.266H172.41a12.539 12.539 0 01-7.097-2.191 12.59 12.59 0 0010.422 5.502h41.115c3.342 0 6.546-1.329 8.909-3.693a12.615 12.615 0 003.691-8.916V69.424A12.604 12.604 0 00223.938 59z"
                ></path>
                <path
                    fill="#fff"
                    d="M184.602 67.676a22.054 22.054 0 012.371-1.77 16.774 16.774 0 012.753-1.439 17.636 17.636 0 013.214-.964 18.548 18.548 0 013.713-.354 15.58 15.58 0 014.987.75 11.05 11.05 0 013.825 2.143 9.564 9.564 0 012.463 3.354c.597 1.38.894 2.873.872 4.378 0 1.552-.219 2.894-.658 4.025a11.257 11.257 0 01-1.651 2.956 12.6 12.6 0 01-2.188 2.174 164.35 164.35 0 01-2.265 1.713 16.96 16.96 0 00-1.852 1.579 3.328 3.328 0 00-.962 1.729l-.765 4.377h-5.783l-.582-4.954c-.142-.978-.02-1.83.366-2.556a7.556 7.556 0 011.562-1.99 20.398 20.398 0 012.187-1.745 21.889 21.889 0 002.264-1.807 9.5 9.5 0 001.768-2.175 5.49 5.49 0 00.72-2.863c0-1.347-.465-2.418-1.393-3.213-.929-.796-2.154-1.197-3.673-1.205a9.411 9.411 0 00-2.83.366c-.681.21-1.341.483-1.973.812-.552.295-1.024.565-1.415.81a2.074 2.074 0 01-1.102.369 1.888 1.888 0 01-1.743-1.01l-2.23-3.49zm5.72 35.945a4.836 4.836 0 01.384-1.913 4.766 4.766 0 011.061-1.562 5.126 5.126 0 017.098 0 4.829 4.829 0 011.379 4.422 4.847 4.847 0 01-2.97 3.555 5.46 5.46 0 01-3.931 0 4.796 4.796 0 01-3.015-4.502h-.006z"
                ></path>
                <path
                    fill="#00B9C3"
                    d="M77.203 20.164H36.088c-6.959 0-12.6 5.645-12.6 12.608v32.375c0 6.963 5.641 12.608 12.6 12.608h41.115c6.959 0 12.6-5.645 12.6-12.608V32.772c0-6.963-5.641-12.608-12.6-12.608z"
                ></path>
                <path
                    fill="#006F75"
                    d="M84.309 22.353a12.559 12.559 0 012.187 7.102v33.843c0 2.956-1.173 5.792-3.263 7.883a11.137 11.137 0 01-7.877 3.265H32.773a12.534 12.534 0 01-7.095-2.19 12.587 12.587 0 0010.41 5.506h41.116c3.341 0 6.546-1.328 8.909-3.693a12.613 12.613 0 003.69-8.915V32.77a12.604 12.604 0 00-5.494-10.418z"
                ></path>
                <path
                    fill="#fff"
                    d="M55.56 53.761h-1.031c-.036-1.939.06-3.39.286-4.356.227-.966.819-2.29 1.776-3.97.955-1.686 1.583-3.038 1.883-4.057.3-1.018.45-2.28.45-3.787 0-2.323-.469-4.035-1.407-5.136-.94-1.1-2.049-1.651-3.33-1.652-.975 0-1.792.253-2.452.76-.5.384-.748.785-.747 1.202 0 .281.169.718.507 1.31.766 1.346 1.149 2.49 1.149 3.432.01.4-.064.796-.218 1.166-.154.37-.385.704-.677.982-.292.286-.64.51-1.024.66-.383.148-.793.22-1.205.209a3.398 3.398 0 01-1.366-.256 3.341 3.341 0 01-1.141-.78c-.675-.69-1.013-1.602-1.013-2.736 0-2.06.91-3.866 2.732-5.42C50.553 29.776 53.1 29 56.37 29c3.463 0 6.1.808 7.913 2.423C66.094 33.04 67 34.991 67 37.282a8.12 8.12 0 01-1.407 4.598c-.938 1.402-2.75 2.961-5.435 4.677-1.803 1.153-2.988 2.18-3.556 3.08-.568.898-.915 2.274-1.042 4.124zm-.218 3.826a4.344 4.344 0 012.717.966 4.192 4.192 0 011.476 2.435c.19.954.037 1.944-.434 2.8a4.261 4.261 0 01-2.146 1.896 4.37 4.37 0 01-2.886.132 4.285 4.285 0 01-2.317-1.692 4.143 4.143 0 01.541-5.308c.396-.397.87-.712 1.395-.923a4.234 4.234 0 011.654-.306z"
                ></path>
                <path
                    fill="#133854"
                    d="M84.472 122.219c9.28 0 16.078 5.486 16.078 13.343v.327c0 7.521-4.837 10.794-9.673 12.882l-1.438.59c-1.634.718-2.157 1.635-2.157 3.597v2.354h-7.516V152.5c0-4.247 1.307-6.933 5.423-8.698l1.375-.589c4.182-1.831 6.41-3.466 6.41-7.253 0-4.12-3.537-6.67-8.488-6.67-5.164 0-9.15 2.55-9.15 7.586v1.832H67.82v-2.159c-.014-8.966 7.24-14.33 16.652-14.33zm-.98 36.297c3.266 0 5.685 2.354 5.685 5.624 0 3.336-2.419 5.691-5.685 5.691-3.203 0-5.622-2.355-5.622-5.691 0-3.27 2.42-5.624 5.622-5.624z"
                ></path>
                <path
                    fill="#000"
                    d="M155.752 0c6.419 0 11.121 3.801 11.121 9.228v.227c0 5.202-3.345 7.464-6.689 8.911l-.996.407c-1.13.498-1.491 1.131-1.491 2.488v1.623h-5.199v-1.946c0-2.941.904-4.796 3.753-6.017l.949-.407c2.893-1.267 4.421-2.398 4.421-5.02 0-2.851-2.442-4.615-5.878-4.615-3.57 0-6.327 1.77-6.327 5.247v1.267h-5.199V9.9c0-6.19 5.019-9.901 11.535-9.901zm-.677 26.64c2.26 0 3.933 1.629 3.933 3.892 0 2.3-1.673 3.936-3.933 3.936-2.214 0-3.891-1.63-3.891-3.936-.005-2.263 1.668-3.893 3.882-3.893h.009z"
                ></path>
                <path
                    fill="#133854"
                    d="M112.175 145.188c0 14.281.049 78.3.049 107.542h5.187c2.913-7.255 22.328-93.751 24.298-107.542h-29.534z"
                ></path>
                <path
                    fill="#F0CCC0"
                    d="M117.411 254.627v-1.897h-5.187a12.801 12.801 0 01-.592 3.451c1.347.52 3.319-.819 5.779-1.554z"
                ></path>
                <path
                    fill="#000"
                    d="M117.411 254.627c-2.46.735-4.432 2.074-5.779 1.554-.708.52-11.231 4.304-12.303 5.036-1.072.733-.934 1.915.225 1.915 1.696 0 15.661-.616 15.661-.616.283.308.511.616.511.616h3.192c.951-1.651.695-6.606-1.507-8.505z"
                ></path>
                <path
                    fill="#F0CCC0"
                    d="M155.542 254.627v-1.897h-5.187a12.797 12.797 0 01-.594 3.451c1.349.52 3.319-.819 5.781-1.554z"
                ></path>
                <path
                    fill="#000"
                    d="M155.541 254.627c-2.462.735-4.432 2.074-5.781 1.554-.707.52-11.231 4.304-12.301 5.036-1.07.733-.934 1.915.226 1.915 1.695 0 15.66-.616 15.66-.616.281.308.511.616.511.616h3.183c.96-1.651.702-6.606-1.498-8.505z"
                ></path>
                <path
                    fill="#2C5D83"
                    d="M150.422 145.188c.293 5.417 5.128 78.3 5.128 107.542h-5.187c-2.912-7.255-27.5-93.751-29.467-107.542h29.526z"
                ></path>
                <path
                    fill="#ED9F00"
                    d="M125.08 83.24c-4.787 6.403-18.49 22.786-21.735 25.569-3.183 2.741-8.135 9.49.177 8.287 7.604-2.123 20.794-7.099 24.63-13.475 3.836-6.376-3.072-20.38-3.072-20.38z"
                ></path>
                <path
                    fill="#FCBA06"
                    d="M150.422 145.188h-38.247c.997-13.834 1.296-49.017 7.639-56.764 7.504-9.161 12.885-8.105 20.24-.745 9.918 9.925 11.887 45.98 10.368 57.509z"
                ></path>
                <path
                    fill="#ED9F00"
                    d="M119.528 145.206l-1.768-.037c.295-13.98.624-21.727.865-27.386.132-3.136.236-5.612.304-8.277.272-10.813 4.658-21.98 6.835-25.287l1.476.972c-1.814 2.758-6.274 13.686-6.543 24.36-.067 2.679-.177 5.163-.306 8.306-.24 5.652-.568 13.392-.863 27.349z"
                ></path>
                <path
                    fill="#F0CCC0"
                    d="M134.429 73.344c-.227 1.849-.313 8.88 1.252 10.893-1.129.858-7.086 2.065-11.541.28.957-1.182 2.476-1.977 1.249-4.07-1.33-2.283-3.58.785-5.364-2.956-2.08-4.362-2.709-12.965-.645-15.361 2.298-2.683 6.91-5.964 13.135-2.3 5.077 2.972 2.37 9.757 1.914 13.514z"
                ></path>
                <path
                    fill="#000"
                    d="M129.746 69.121c-1.415.022-.691-3.686-1.729-4.569-1.228-1.044-5.33-1.647-9.306-1.207-3.975.441-6.456-4.753-3.917-6.9 2.54-2.15 4.858.813 6.754.176 2.207-.738 1.818-3.435 5.098-3.152 3.033.26 1.657 3.083 3.583 3.584 1.986.515 4.904-2.124 7.33.993 1.8 2.31-.906 3.66-.617 6.232.177 1.605 4.175 6.344-.133 8.354-4.308 2.01-3.314-2.4-3.087-4.219.226-1.819-.596-3.02-1.507-3.057-.911-.037-.453 3.739-2.469 3.765z"
                ></path>
                <path
                    fill="#ED9F00"
                    d="M135.112 82.54c-1.061.53-7.648 1.686-9.913.365a6.482 6.482 0 00-1.061 1.612c1.579.946 7.505 1.946 11.646-.177-.26-.72-.672-1.8-.672-1.8z"
                ></path>
                <path
                    fill="#FFD03E"
                    d="M139.865 87.389c6.946 5.84 21.144 24.133 21.144 37.592 0 13.46-11.376 26.21-15.111 29.947l-4.536-4.539c2.764-2.766 10.102-21.047 8.74-26.19-1.362-5.142-7.832-10.713-13.576-16.457-5.744-5.744-6.297-10.585-5.289-14.215 1.008-3.63 5.729-8.572 8.628-6.138z"
                ></path>
                <path
                    fill="#F0CCC0"
                    d="M141.363 150.389c-1.309 0-2.165.049-3.325 1.209 1.16.605 7.861 3.33 7.861 3.33l-4.536-4.539z"
                ></path>
                <path
                    fill="#ED9F00"
                    d="M145.933 155.197s-4.868-5.093-5.236-5.378a30.85 30.85 0 012.609-3.794c1.038.811 6.391 5.429 6.414 5.654-.46.541-3.787 3.518-3.787 3.518z"
                ></path>
                <path
                    fill="#FFD03E"
                    d="M118.327 80.344c-1.758 2.897-28.874 31.967-22.3 35.706 4.747 2.699 10.081.487 12.733-3.487 1.818-2.717 13.504-27.39 13.753-29.07-1.245-.509-4.186-3.149-4.186-3.149z"
                ></path>
                <path
                    fill="#ED9F00"
                    d="M118.386 80.06c-.752.291-3.616 3.952-3.616 3.952.031 1.014.353 2.517 1.653 3.362 1.547 1.006 3.834.613 4.794.07.561-1.292 1.498-3.317 2.055-4.624.729-1.717-4.886-2.76-4.886-2.76z"
                ></path>
                <path
                    fill="#E8B7AA"
                    d="M120.737 78.058c2.626-.326 6.08-2.55 6.474-2.085.395.466-3.07 3.067-3.821 3.35.148 2.185-.297 3.736-1.351 4.728-1.401 1.32-3.953.896-4.165-1.74-.049-.612.34-4.657 1.83-5.579.351.821.503 1.39 1.033 1.326z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_1_2363">
                    <path fill="#fff" d="M0 0H252V268H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}
